.loading-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  }
  
  .loading-circle {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #7D8CC4;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  .loading-circle-button {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #7D8CC4;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  