:root {
  --blue: #1e90ff;
  --white: #ffffff;
}

.App {
  background-color: #eee;
  width: 50%;
  min-width: 700px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 30px;
}

.input-field input {
  border: 1px solid #ccc;
  height: 30px;
  width: 100%;
}

.input-field select {
  border: 1px solid #ccc;
  height: 34px;
  width: 100%;
}

.input-field label {
  font-weight: bold;
  font-size: 12px;
}

.button-blue {
  background-color: #00f;
  height: 30px;
  margin-top: 20px;
  border: none;
  width: 110px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.disabled {
  opacity: 50%;
  cursor: default !important;
}

.button-blue:hover {
  cursor: pointer;
  opacity: 50%;
}

.response-parent-div {
  border: solid 1px #ccc;
  background-color: white;
  overflow-x: scroll;
  min-height: 500px;
}